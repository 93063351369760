import React, { useState, useEffect, useRef } from "react";
import { Select, Modal, Button, message, Upload } from "antd";
import { Input, notification, Spin } from "antd";
import { RadiusBottomleftOutlined } from "@ant-design/icons";
import { UploadOutlined } from "@ant-design/icons";
import { getMessageRecipientsAPI } from "../../../../shared/actions/MessagingActions/ListMessagesRecipients";
import { listMessageRolesAPI } from "../../../../shared/actions/MessagingActions/LoadMessagingRoles";
import { sendMessageAPI, sendMessageAPIRefresher } from "../../../../shared/actions/MessagingActions/SendingMessages";
import { connect, useDispatch, useSelector } from "react-redux";
import PdfFileComp from "../MessageAttachments/PdfFileComp";
import JpgFileComp from "../MessageAttachments/JpgFileComp";
import AttachmentsHandler from "../MessageAttachments/AttachmentsHandler";
import renderHTML from 'react-render-html';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import getUserId from "../../../../shared/core/GetToken";
import Settings from "../../../../shared/config/Settings";
import { actionAPI } from "../../../../shared/reduxToolkit/actionAPI";

const { Option } = Select;
const { TextArea } = Input;

const ComposeMsgModal = (props) => {
  const { recipientsData, recipientsDataSuccess, recipientsDataLoading } = useSelector(state => state.ListMessageRecipients)
  const { Profile, isFetching } = useSelector(state => state.UserProfile);

  const { rolesData, rolesDataSuccess } = useSelector(state => state.MessageRolesList)
  const { sendMessage, sendMessageLoading, sendMessageSuccess, sendMessageFailed } = useSelector(state => state.SendingMessage)

  const [msgRoleId, setMsgRoleId] = useState(rolesDataSuccess && rolesData && rolesData[0].entry ? rolesData[0].entry[0].resource.id : "");
  const [msgRoleName, setMsgRoleName] = useState(rolesDataSuccess && rolesData && rolesData[0].entry ? rolesData[0].entry[0].resource.title : "");
  const [msgRecipientId, setMsgRecipientId] = useState();
  const [msgRecipientName, setMsgRecipientName] = useState();
  const [msgSubject, setMsgSubject] = useState("");
  const [msgBody, setMsgBody] = useState("");
  const [msgBodyErrMsg, setMsgBodyErrMsg] = useState("");
  const [providerErrMsg, setProviderErrorMsg] = useState("");
  const [rolesErrMsg, setRolesErrMsg] = useState("");
  const [msgSubErrMsg, setsetMsgSubErrMsg] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([])
  const [filesState, setFilesState] = useState([])
  const [imageFiles, setImageFiles] = useState([])
  const [isLoadingAvailableProvidersData, setIsLoadingAvailableProvidersData] = useState(false)
  const fileInputRef = useRef(null)
  let formatFiles = []

  const apiDispatcher = useDispatch()
  const onRolesChange = (value, option) => {
    setRolesErrMsg("")
    setMsgRoleId(value);
    setMsgRoleName(option.children);

  };
  useEffect(() => {
    setMsgRecipientId("");
    setMsgRecipientName("");

    if (msgRoleId != "") {
      const values = {
        roleID: msgRoleId,
      };
      apiDispatcher(actionAPI.ListMessageRecipientsAPI(values))

    }
  }, [msgRoleId])
  useEffect(() => {
    if (rolesDataSuccess && rolesData && rolesData[0].entry) {
      const isDoctorRoleAvailable = rolesData[0]?.entry?.find(o => o?.resource?.title?.includes("Doctor"))
      if (JSON.parse(localStorage.getItem("@portalSettings"))?.DisableAppointment && isDoctorRoleAvailable) {

        setMsgRoleId(isDoctorRoleAvailable.resource?.id)
        setMsgRoleName(isDoctorRoleAvailable.resource?.title)

      } else {
        setMsgRoleId(rolesData[0]?.entry[0]?.resource?.id)
        setMsgRoleName(rolesData[0]?.entry[0]?.resource?.title)
      }
    }
  }, [rolesData])
  useEffect(() => {
    if (recipientsDataSuccess && recipientsData && recipientsData[0].entry && Profile) {
      debugger
      let defaultGP = Profile && Profile[0]?.resource?.extension?.find(o => o.url === "GPID")?.valueString
      let gpExistsInRecipients = false

      recipientsData[0]?.entry.map(item => {
        if (item.resource.id === defaultGP) {
          gpExistsInRecipients = true
        }
      })
      if (JSON.parse(localStorage.getItem("@portalSettings"))?.DisableAppointment && (msgRoleId === "0" || msgRoleId === "1")) {
        if (gpExistsInRecipients) {
          setMsgRecipientId(Profile[0]?.resource?.extension?.find(o => o.url === "GPID")?.valueString)
          setMsgRecipientName(Profile[0]?.resource?.extension?.find(o => o.url === "GPName")?.valueString)

        } else {
          setMsgRecipientId("")
          setMsgRecipientName("")
        }
      } else {
        setMsgRecipientId("")
        setMsgRecipientName("")
      }
    } else {
      setMsgRecipientId("")
      setMsgRecipientName("")
    }

  }, [recipientsData, isFetching, recipientsDataLoading])
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: 'Notification',
      description:
        message,
    });
  };
  const onProviderChange = (value, option) => {
    setMsgRecipientId(option.key);
    setMsgRecipientName(option.children);
    setProviderErrorMsg("")
  };
  //  useEffect(()=>{
  //   if(msgRoleId){
  //     const values = {
  //       roleID: msgRoleId,
  //     };
  //     props.getMsgRecipients(values);
  //   }

  // },[msgRoleId])
  const checkProviderAvailable = (msgPayload, fromDraft = false) => {
    setIsLoadingAvailableProvidersData(true)
    getUserId().then((userInfo) => {
      const item = JSON.parse(userInfo);
      var myHeaders = new Headers();
      myHeaders.append("Token", item.token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        ProviderID: msgRecipientId,
        PatientID: item.userid,
        FromCompose: 1

      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: raw,

      };
      fetch(Settings.apiUrl + "/Patient/LoadOnlyAvaliableProviders", requestOptions)
        .then(data => data.json())
        .then(json => {
          console.log(json)
          if (json && json[0].IsLeave === false) {
            setIsLoadingAvailableProvidersData(false)

            apiDispatcher(actionAPI.SendingMessagesAPI(msgPayload))

          }
          else {
            setIsLoadingAvailableProvidersData(false)

            openNotificationWithIcon("error", `As per practice policy, you cannot${fromDraft ? " draft " : " send "}the message as provider is on leave`)
          }
        })
        .catch(err => {
          console.log(err);
          setIsLoadingAvailableProvidersData(false)
        })
    })
  }

  const checkValidations = () => {
    let caughtErr = false

    if (msgRecipientId === null || msgRecipientId?.trim() == "") {
      setProviderErrorMsg("Please Select a Provider")
      caughtErr = true
    }
    if (msgRoleId === null || msgRoleId?.trim() == "") {
      setRolesErrMsg("Please Select a Role")
      caughtErr = true
    }
    if (msgBody?.trim() == "") {
      setMsgBodyErrMsg("Please type something in the body of the message")
      caughtErr = true
    }
    if (msgSubject?.trim() == "") {
      setsetMsgSubErrMsg("Please type something in the subject of the message")
      caughtErr = true
    }
    return caughtErr
  }

  const handleSendClick = () => {
    let validateErr = checkValidations()
    if (validateErr) {
      return
    } else {
      // if (filesState.length > 0) {

      //   try {
      //     PostTempFileData()
      //   } catch (error) {

      //   }
      // }
      const values = {
        centre: {
          id: "Location/" + msgRoleId,
          name: msgRoleName,
        },
        practitioner: {
          id: "Practitioner/" + msgRecipientId,
          name: msgRecipientName,
        },
        message: {
          subject: msgSubject,
          body: msgBody,
        },
        selectedFiles: selectedFiles,
        messageType: "Send Mail",
        roleID: msgRoleId,
      };

      // apiDispatcher(actionAPI.SendingMessagesAPI(values))
      checkProviderAvailable(values, false)
    }
  };
  const handleDraftClick = () => {
    let validateErr = checkValidations()
    if (validateErr) {
      return
    }
    // if (filesState.length > 0) {

    //   try {
    //     PostTempFileData()
    //   } catch (error) {

    //   }
    // }
    const values = {
      centre: {
        id: "Location/" + msgRoleId,
        name: msgRoleName,
      },
      practitioner: {
        id: "Practitioner/" + msgRecipientId,
        name: msgRecipientName,
      },

      message: {
        subject: msgSubject,
        body: msgBody,
      },
      selectedFiles: selectedFiles,
      messageType: "Draft",
      roleID: msgRoleId,

    };
    // apiDispatcher(actionAPI.SendingMessagesAPI(values))
    checkProviderAvailable(values, true)


  };
  const readURL = (e) => {

    const input = e.target

    if (input.files.length > 0) {
      var fileName = input.files[0];
      // console.log("filenmae", fileName)
      var fileTypes = ['.pdf', '.gif', '.jpg', '.png', '.jpeg', '.bmp', '.JPG', '.docx', '.doc'];
      var fileSize = input.files[0].size;
      if (fileSize > 5000000)  //3000000 max 3 MB
      {
        openNotificationWithIcon("error", "File size can not be greator than 5mb");
        return false;
      }
      let dots = fileName.name.split(".")
      let fileType = ("." + dots[dots.length - 1]).toLowerCase();
      if (fileTypes.join(".").indexOf(fileType) != -1) {
        //if (input.id == "UploadFileID")
        PostFileCollection(fileName.name, input.files);
        //else
        //    PostFileCollectionDesktop(fileName.name);

      }
      else {
        openNotificationWithIcon("error", "Invalid file type.Only pdf & images are allowed");
        return false;
      }
    }
  }
  function PostFileCollection(fileName, filesList) {
    var formData = new FormData();
    var totalFiles = filesList.length; //"UploadFileID"
    if (totalFiles < 1) {
      return;
    }
    if (totalFiles > 4) {
      openNotificationWithIcon("error", "Please select atleast four files.");
      return;
    }

    for (var i = 0; i < totalFiles; i++) {
      var file = filesList[i];//"UploadFileID"
      if (file.type == "image/png" || file.type == "image/jpg" || file.type == "image/jpeg" || file.type == "image/gif" || file.type == "image/bmp" || file.type == "application/pdf" || file.type == "application/docx" || file.type == "application/doc" || file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        //upload
        formData.append(file.name, file);//"UploadFileID"
      }
      else {
        openNotificationWithIcon("error", "Invalid file format");

        return;
      }
    }
    // PostTempFileData(formData)
    // getGUIDAttachment(formData, fileName);
  }
  const PostTempFileData = async () => {
    const itemUserInfo = await getUserId().then((userInfo) => {
      return JSON.parse(userInfo);
    });

    const apiUrl = Settings.apiUrl + "Patient/PostTempMessageFiles";


    const formData = new FormData();


    for (let i = 0; i < filesState.length; i++) {
      formData.append(`files_${i}`, filesState[i]);
    }
    formData.append('patientid', itemUserInfo.userid.toString());
    formData.append('messageid', 0);
    // console.log(formData)

    fetch(apiUrl, {
      method: 'POST',
      body: formData,
      processData: false,  // Set processData to false to prevent jQuery from serializing the data
      //contentType: false,  // Set contentType to false to let the browser set the Content-Type

      headers: {
        // Add this line to specify the content type

        'token': itemUserInfo.token
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.text(); // Assuming the server responds with a text message
        } else {
          //throw new Error('Error uploading files');
        }
      })
      .then((data) => {
        console.log(data); // Response from the server
      })
      .catch((error) => {
        console.error(error);
      });

  }
  const handleRemoveFile = (index) => {
    if (selectedFiles.length > 0) {
      setSelectedFiles(selectedFiles.filter((file, fileIndex) => fileIndex != index))
      setFilesState(filesState.filter((file, fileIndex) => fileIndex != index))

    }
  }
  const imageTypeRegex = /image\/(png|jpg|jpeg|heif|heic)/gm;
  const pdfTypeRegex = /application\/(pdf)/gm;
  const selectFilesHandler = (e) => {
    const { files } = e.target;
    console.log("filesssss", files)

    const validImageFiles = [];
    if (selectedFiles.length + files.length > 4) {
      openNotificationWithIcon("error", "Only four files are allowed to be uploaded")
      fileInputRef.current.value = "" 
      return
    }
    let sizeLimitExceed = false


    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 6000000) {
        sizeLimitExceed = true
      }
      if (file.type.match(imageTypeRegex) || file.type.match(pdfTypeRegex) || (file.type === "" && (file.name.includes("heif") || file.name.includes("heic")))) {
        validImageFiles.push(file);
      }
    }
    if (sizeLimitExceed) {
      openNotificationWithIcon("error", "File size cannot be greater than 6MB")
      fileInputRef.current.value = ""
      return
    }
    try {
      if (filesState.length + files.length < 5) {

        if (filesState.length > 0) {
          setFilesState([...filesState, ...files])
        } else {
          setFilesState([...files])

        }
      }
    } catch (error) {
      fileInputRef.current.value = ""
    }
    if (validImageFiles.length) {
      setImageFiles(validImageFiles);
      fileInputRef.current.value = ""
      return;
    }
    openNotificationWithIcon("error", "Selected images are not of valid type!");
    fileInputRef.current.value = ""
  };
  useEffect(() => {
    const images = [], fileReaders = [];
    let isCancel = false;
    if (imageFiles.length) {
      imageFiles.forEach((file) => {
        let data = {}
        const fileReader = new FileReader();
        fileReaders.push(fileReader);
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result) {
            data = {
              contentAttachment: {
                contentType: result.split("/")[1].split(";base64")[0],
                data: result.split("base64,")[1]

              }
            }
            images.push(data)
          }
          if (images.length === imageFiles.length && !isCancel) {
            setSelectedFiles((prev) => prev.concat(images));
          }
        }
        fileReader.readAsDataURL(file);
      })
    };
    return () => {
      isCancel = true;
      fileReaders.forEach(fileReader => {
        if (fileReader.readyState === 1) {
          fileReader.abort()
        }
      })
    }
  }, [imageFiles]);
  useEffect(() => {
  }, [selectedFiles])

  return (
    <>
      <Modal
        title="New Message"
        width={800}
        className="compose-msg-modal"
        open={props.isComposeModalOpen}
        onCancel={props.handleCancel}
        afterClose={() => {
          setIsLoadingAvailableProvidersData(false)
        }}
        footer={[
          <Button
            key="cancel"
            onClick={props.handleCancel}
            className="cancel-msg-btn"
            disabled={sendMessageLoading || isLoadingAvailableProvidersData}
          >
            Cancel
          </Button>,
          <Button
            key="draft"
            onClick={handleDraftClick}
            className="draft-msg-btn"
            disabled={sendMessageLoading || isLoadingAvailableProvidersData}
          >
            Draft
          </Button>,
          <Button
            key="send"
            onClick={handleSendClick}
            className="send-msg-btn"
            disabled={sendMessageLoading || isLoadingAvailableProvidersData}

          >
            Send
          </Button>,
        ]}
      >
        <div className="row alert alert-warning compose-msg-diclaimer">
          <h6 className="alert-heading">Disclaimer:</h6>
          {props.disclaimerMsg != "" &&
            renderHTML(props.disclaimerMsg)
          }

        </div>
        {!rolesDataSuccess ? <Spin /> :
          <div className="row">
            <label className="col-3 compose-msg-h-text">To</label>
            <Select
              className="col-8 compose-msg-select"
              showSearch
              disabled={JSON.parse(localStorage.getItem("@portalSettings"))?.DisableAppointment}
              placeholder="To"
              optionFilterProp="children"

              onChange={onRolesChange}
              value={
                msgRoleId
              }
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <Option value={""}>Select</Option>

              {rolesDataSuccess
                ? rolesData[0].entry?.map((data) => (
                  <Option
                    value={data.resource.id}
                    key={data.resource.id}
                  >
                    {data.resource.title}
                  </Option>
                ))
                : null}
            </Select>
          </div>
        }
        <p className="text-danger">{rolesErrMsg}</p>
        <div className="row">

          <label className="col-6 col-sm-3 compose-msg-h-text">Practitioner</label>

          <Select
            className="col-8 w-100"
            showSearch
            placeholder="Select Provider"
            optionFilterProp="children"
            disabled={JSON.parse(localStorage.getItem("@portalSettings"))?.DisableAppointment}
            value={msgRecipientId}
            onChange={onProviderChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            <Option value={""}>Select</Option>
            {recipientsDataSuccess && msgRoleId != "" && recipientsData?.length > 0 && recipientsData[0]?.entry
              ? recipientsData[0]?.entry?.slice()?.sort((a, b) => a?.resource?.name[0].text > b?.resource?.name[0].text ? 1 : -1)?.map((data) => (
                <Option
                  value={data.resource.extension[0].valueString}
                  key={data.resource.extension[0].valueString}
                >
                  {data.resource.name[0].text}
                </Option>
              ))
              : null}
          </Select>
        </div>

        <p className="text-danger">{providerErrMsg}</p>

        <div className="row">
          <label className="col-3 m-0 compose-msg-h-text">Subject</label>

          <Input
            autoComplete="off"
            className="col-8"
            placeholder="Subject"
            value={msgSubject}
            onChange={(e) => { setMsgSubject(e.target.value); setsetMsgSubErrMsg("") }}
          />
        </div>
        <p className="text-danger">{msgSubErrMsg}</p>
        {/* <TextArea
          // value={value}
          // onChange={(e) => setValue(e.target.value)}
          placeholder="Write your message here"
          autoSize={{ minRows: 10, maxRows: 10 }}
          className="compose-msg-textarea"
          value={msgBody}
          onChange={(e) => { setMsgBody(e.target.value); setMsgBodyErrMsg("") }}
          maxLength={5000}
        /> */}
        <CKEditor

          editor={ClassicEditor}
          data={msgBody}
          config={{ toolbar: [], placeholder: "Write your message here." }}

          onChange={(event, editor) => {
            const data = editor.getData();
            setMsgBody(data);
            setMsgBodyErrMsg("")
          }}

        />
        <p className="text-danger">{msgBodyErrMsg}</p>


        <label htmlFor="file" className="p-2 text-primary fw-bold mt-3 px-3 choose-file">Choose Files
        </label>
        <input
          autoComplete="off"
          type="file"
          id="file"
          ref={fileInputRef}
          onChange={selectFilesHandler}
          // onChange={readURL}
          accept="image/png, image/jpg, image/jpeg, image/heif, image/heic,application/pdf"
          multiple
        />
        {
          selectedFiles.length > 0 ?
            <AttachmentsHandler selectedFiles={selectedFiles} type="composing" handleRemoveFile={handleRemoveFile} /> : null
        }

      </Modal>
    </>
  );
};


function mapStateToProps(state) {
  return {
    TokenValidator: state.TokenValidator,

    MessageRecipients: state.MessagesRecipientsReducer,
    SendingMessage: state.SendingMessagesReducer,
    MessagingRoles: state.LoadMessagingRolesReducer,
    Profile: state.Profile,

  }
}
function mapDispatchToProps(disptach) {
  return {

    getMsgRecipients: (values) => disptach(getMessageRecipientsAPI(values)),
    sendMessage: (values) => disptach(sendMessageAPI(values)),
    sendMessageRefreshData: () => disptach(sendMessageAPIRefresher()),
    loadMsgRoles: () => disptach(listMessageRolesAPI()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComposeMsgModal)
// export default
