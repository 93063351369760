import React, { useEffect, useState } from "react";
import MessageListing from "../../components/MessagingComponents/MessageListing";
import MessageContent from "../../components/MessagingComponents/MessageContent";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import getUserId from "../../../shared/core/GetToken";
import Settings from "../../../shared/config/Settings";
import { connect, useDispatch, useSelector } from "react-redux";
import { actionAPI } from "../../../shared/reduxToolkit/actionAPI";

const Messaging = (props) => {
  const [disclaimerMsg, setDisclaimerMsg] = useState("");
  const [draftDisclaimerMsg, setDraftDisclaimerMsg] = useState("");
  const [refreshMessagesTrigger, setRefreshMessagesTrigger] = useState(0)
  const [activeMessage, setActiveMessage] = useState(null)
  const apiDispatcher = useDispatch()
 
  const navigate = useNavigate()
  const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
  useEffect(() => {
    if (ProxyMenu != null && ProxyMenu != undefined) {
      let itemExists = false
      ProxyMenu.map((item, index) => {
        if (item.resource.extension[0].valueString == 'Messaging') {
          itemExists = true
        }

      })
      if (!itemExists) {
        navigate("/")
      }
    }
  }, [ProxyMenu])
  const getComposeMsgDisclaimer = (loadType)=>{
      getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);


        var myHeaders = new Headers();
        myHeaders.append("Token", item.token)
        myHeaders.append("Content-Type", "application/json")
        var raw = {
          PatientID: item.userid,
          loadType: loadType
        }
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(raw)
        };

        fetch(Settings.apiUrl + "Patient/TermsAndCondition", requestOptions)

                .then(data => data.json())
                .then(json => {
                  if(loadType == 1){
                    setDisclaimerMsg(json)
                  }else if(loadType==2){
                    setDraftDisclaimerMsg(json)
                  }
                  
                })      
                .catch(error =>{
                  if(loadType == 1){
                    setDisclaimerMsg(null)
                  }else if(loadType==2){
                    setDraftDisclaimerMsg(null)
                  }
                })
      })
  }
  useEffect(()=>{
    getComposeMsgDisclaimer(1)
    getComposeMsgDisclaimer(2)
  },[])
  useEffect(() => {
    console.log(activeMessage)
  }, [activeMessage])
  React.useEffect(() => {
    apiDispatcher(actionAPI.checkToken(navigate))
  }, [])
  return (
    
            <div className="page-content-wrapper-inner">
              <div className="viewport-header">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb has-arrow">
                    <li className="breadcrumb-item">
                      <Link to="/">Dashboard</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Messaging</li>
                  </ol>
                </nav>

                
              </div>
              <div className="content-viewport">
                <div className="row row-margin-zero h-100">
                  <div className="col-12 h-100">
                    <div className="grid m-0 h-100">
                      {/* <div className="grid-body"> */}
                      <div className="msgBox">
              <div className="mainContent d-flex">
                  <div className={`${activeMessage ? 'msgListing-mobile msgListing' : 'msgListing'}`} >

                    <MessageListing disclaimerMsg={disclaimerMsg} msgsRefreshTrigger={refreshMessagesTrigger} setMsgsRefreshTrigger={setRefreshMessagesTrigger} setActiveMessage={setActiveMessage} />
                  </div>
                  <div className={`msgContent  flex-column align-items-center justify-content-center position-relative ${activeMessage ? 'd-flex' : 'd-none'}`}>
                  
                    <MessageContent draftDisclaimerMsg={draftDisclaimerMsg} disclaimerMsg={disclaimerMsg} msgsRefreshTrigger={refreshMessagesTrigger} setMsgsRefreshTrigger={setRefreshMessagesTrigger} setActiveMessage={setActiveMessage} />
                  </div>
</div>
              </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
      
  );
};
function mapStateToProps(state) {
  return {
    ProxyMenu: state.ProxyMenu,
  }
}


function mapDispatchToProps(disptach) {
  return {
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messaging)
